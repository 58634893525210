import React, { useRef, Children } from 'react'
import { useSpring, useTrail, animated, config } from 'react-spring'
import { useIntersectionObserver } from '@hooks/useIntersectionObserver'

type AnimationComponentProps = {
  children: React.ReactNode
  threshold?: number
  delay?: number
  className?: string
  onRendered?: () => void
}

/*
	useSpring
*/

export const SpringFadeUp: React.FC<AnimationComponentProps> = props => {
  const children = props.children

  const threshold = props?.threshold || 0.25
  const delay = props?.delay || 150

  const elementRef = useRef<HTMLDivElement>(null)
  const [inView, entry] = useIntersectionObserver(elementRef, {
    threshold,
  })
  const SpringFadeUpConfig = {
    from: {
      opacity: 0,
      transform: 'translate(0px, 3rem)',
    },
    delay,
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate(0px, 0px)' : 'translate(0px, 3rem)',
  }

  const fade = useSpring({
    config: config.default,
    ...SpringFadeUpConfig,
  })

  if (inView && props?.onRendered) {
    props.onRendered()
  }
  return (
    <div
      className={`${props.className ? props.className : ''} ${
        entry ? 'is-visible' : ''
      }`}
      ref={elementRef}
    >
      <animated.div style={fade}>{children}</animated.div>
    </div>
  )
}

/*
	useTrail
*/
export const TrailFadeUp: React.FC<AnimationComponentProps> = props => {
  const children = props.children
  const threshold = props?.threshold || 0.25
  const delay = props?.delay || 150
  const elementRef = useRef<HTMLDivElement>(null)
  const [inView, entry] = useIntersectionObserver(elementRef, {
    threshold,
  })
  const TrailFadeUpConfig = {
    from: {
      opacity: 0,
      transform: 'translate(0px, 24px)',
    },
    delay,
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate(0px, 0px)' : 'translate(0px, 24px)',
  }

  const trail = useTrail(Children.count(children), {
    config: config.default,
    ...TrailFadeUpConfig,
  })
  if (inView && props?.onRendered) {
    props.onRendered()
  }
  return (
    <div className={props.className} ref={elementRef}>
      {trail.map((element: any, key: number) => (
        <animated.div key={`animation-${key}`} style={element}>
          {Children.toArray(children)[key]}
        </animated.div>
      ))}
    </div>
  )
}

/* Only Opacity */

export const Fade: React.FC<AnimationComponentProps> = props => {
  const children = props.children
  const threshold = props?.threshold || 0.8
  const delay = props?.delay || 100

  const elementRef = useRef<HTMLDivElement>(null)
  const [inView, entry] = useIntersectionObserver(elementRef, {
    threshold,
  })
  const SpringFadeUpConfig = {
    from: {
      opacity: 0,
    },
    delay,
    opacity: inView ? 1 : 0,
  }

  const fade = useSpring({
    config: config.default,
    ...SpringFadeUpConfig,
  })

  if (inView && props?.onRendered) {
    props.onRendered()
  }
  return (
    <div className={props.className} ref={elementRef}>
      <animated.div style={fade}>{children}</animated.div>
    </div>
  )
}
